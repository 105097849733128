import { ParallaxLayer } from '@react-spring/parallax'
import Image from 'next/image'

export const SecondLandingPageImagePreview = () => (
  <ParallaxLayer speed={1.4} offset={0.9} factor={1.55}>
    <div className={`w-full h-full flex flex-row items-center `}>
      <div className={`w-1/2 h-min flex flex-row justify-end items-center`}>
        <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start relative mr-16`}>
          <div className={`relative`}>
            <div
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[130%] h-[130%] dark:opacity-30 opacity-70 z-0`}
            >
              <img
                src={'/landing/shot1bg.png'}
                className={`dark:contrast-125 dark:brightness-50 hue-rotate-[20deg] w-full h-full`}
              />
            </div>
            <div className={`w-full h-full dark:block hidden z-10`}>
              <Image src={'/landing/shot2.png'} width="1920" height="1080" className={`rounded-xl z-20`}   alt="Screenshot of Disadus"/>
            </div>
            <div className={`w-full h-full dark:hidden block z-10 drop-shadow-md`}>
              <Image
                src={'/landing/shot2light.png'}
                width="2560"
                height="1600"
                className={`rounded-xl z-30`}
                // priority
                alt="Screenshot of Disadus"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
