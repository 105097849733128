import { ParallaxLayer } from '@react-spring/parallax'
import Image from 'next/image'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isMacOs, useDeviceData } from 'react-device-detect'
import { useCurrentUser } from '../../../../utils/hooks/self/useSelfInfo'
export const FirstLandingPageContentPreview = (props: { ready: number; setReady: (ready: number) => void }) => {
  const { ready, setReady } = props
  const videoRef = useRef<HTMLVideoElement>(null)
  const user = useCurrentUser()
  const [theme, setTheme] = useState(user?.theme)
  useEffect(() => {
    setTheme(theme => (user && typeof user.theme !== 'undefined' ? user.theme : theme))
  }, [user])
  useLayoutEffect(() => {
    setTheme(theme =>
      typeof theme !== 'undefined'
        ? theme
        : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 1
        : 0
    )
  }, [])
  const vidlink = isMacOs
    ? theme
      ? '/landing/darkLandingFirstVid4kMac.mp4'
      : '/landing/landingFirstVid4kMac.mp4'
    : theme
    ? '/landing/darkLandingFirstVid4kWin.mp4'
    : '/landing/landingFirstVid4kMac.mp4'

  useLayoutEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoRef.current.duration || 0
    }
    if (videoRef.current && ready == 2) {
      // videoRef.current.play()
    }
    if (videoRef.current && ready >= 4) {
      // videoRef.current.pause()
      videoRef.current.currentTime = videoRef.current.duration
    }
  }, [ready])
  return (
    <ParallaxLayer speed={-2} offset={0}>
      <div className={`w-full h-full flex flex-row items-end justify-end relative`}>
        {/* <div className={`w-1/2 h-min flex flex-row justify-start items-center`}>
          <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start relative`}>
            <div className={`relative`}>
              <div className={`w-full h-full dark:block hidden`}>
                <Image src={'/landing/shot1.png'} width="1920" height="1080" className={`rounded-xl z-20`} priority />
              </div>
              <div className={`w-full h-full dark:hidden block`}>
                <Image
                  src={'/landing/shot1light.png'}
                  width="1920"
                  height="1080"
                  className={`rounded-xl z-20`}
                  // priority
                />
               
              </div>
              <div
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[130%] h-[130%] dark:opacity-30 opacity-70`}
              >
                <img
                  src={'/landing/shot1bg.png'}
                  className={`dark:contrast-125 dark:brightness-50 hue-rotate-[20deg] w-full h-full`}
                />
              </div>
            </div>
          </div>
        </div> */}
        <video
          src={vidlink}
          muted
          loop={false}
          controls={false}
          className={`h-full ${ready >= 4 && `animate-bounce-mini`}`}
          onLoadedData={e => {
            // alert('loaded')
            setReady(1)
          }}
          playsInline={true}
          ref={videoRef}
          id="landingFirstVid"
        />
      </div>
    </ParallaxLayer>
  )
}
