import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'
import { PrimaryButton } from '../../Inputs/Button'

export const NavbarLayer = () => (
  // <ParallaxLayer speed={-1} offset={0} factor={1} className={`pointer-events-none`}>
  <div className={`w-full h-full flex flex-row items-start justify-start fixed pointer-events-none`}>
    <div className={`flex flex-row gap-4 w-full h-20 p-4 dark:bg-black/30 pointer-events-auto`}>
      <Link href="/">
        <img
          src={`/hopperAppIcon.png`}
          className={`w-12 h-12 rounded-xl shadow-lg shadow-cyan-900/70 cursor-pointer hover:shadow-xl hover:shadow-cyan-900/80 transition-all duration-500`}
        />
      </Link>
      <div className={`flex flex-row gap-16 flex-grow h-full items-center justify-center`}>
        <Link href="?sec=top">
          <span
            className={`font-extrabold font-wsans text-2xl text-gray-900/20 dark:text-gray-100/25 uppercase hover:bg-gradient-to-br from-emerald-400 to-cyan-500 before:hover:text-transparent bg-clip-text cursor-pointer`}
          >
            Home
          </span>
        </Link>
        {/* <Link href="/about">
          <span
            className={`font-extrabold font-wsans text-2xl text-gray-900/20 dark:text-gray-100/25 uppercase hover:bg-gradient-to-br from-emerald-400 to-cyan-500 hover:text-transparent bg-clip-text cursor-pointer`}
          >
            About Us
          </span>
        </Link> */}
        <Link href="?sec=faq">
          <span
            className={`font-extrabold font-wsans text-2xl text-gray-900/20 dark:text-gray-100/25 uppercase hover:bg-gradient-to-br from-emerald-400 to-cyan-500 hover:text-transparent bg-clip-text cursor-pointer`}
          >
            FAQ
          </span>
        </Link>
        <Link href="https://discord.gg/X6uJzWFx9k">
          <span
            className={`font-extrabold font-wsans text-2xl text-gray-900/20 dark:text-gray-100/25 uppercase hover:bg-gradient-to-br from-emerald-400 to-cyan-500 dark:from-emerald-600 dark:to-cyan-700 hover:text-transparent bg-clip-text cursor-pointer`}
          >
            Discord
          </span>
        </Link>
      </div>
      <Link href="/app">
        <button
          className={` bg-gradient-to-br from-emerald-400 to-cyan-500 dark:from-emerald-600 dark:to-cyan-700 dark:text-gray-50/80 dark:hover:from-emerald-700 dark:hover:to-cyan-800 transition-all  text-white font-wsans font-bold rounded-xl shadow-lg hover:shadow-xl shadow-cyan-900/50 hover:shadow-cyan-900/70 py-3 px-6 xl:py-2 xl:px-4 w-max h-min`}
        >
          Launch App
        </button>
      </Link>
    </div>
  </div>
  // </ParallaxLayer>
)
