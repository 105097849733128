import Head from 'next/head'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
// import { LandingScroll1 } from '../components/LandingPageComponents/LandingScroll/Scroll1'
// import LandingScroll2 from '../components/LandingPageComponents/LandingScroll/Scroll2'
// import LandingScroll3 from '../components/LandingPageComponents/LandingScroll/Scroll3'
// import LandingScroll4 from '../components/LandingPageComponents/LandingScroll/Scroll4'
// import LandingScroll5 from '../components/LandingPageComponents/LandingScroll/Scroll5'
// import LandingScroll8 from '../components/LandingPageComponents/LandingScroll/Scroll8'
// import { LandingCanvasScrollRenderer } from '../components/LandingPageComponents/LandingScroll/LandingCanvasScrollRenderer'
import { isMobileSafari, isSafari } from 'react-device-detect'
import { NextPage } from 'next'
import Link from 'next/link'
import { IParallax, Parallax } from '@react-spring/parallax'
import { FirstLandingPage } from '../components/Landing/ParallaxLayers/FirstShot/First'
import { FirstLandingPageBackground } from '../components/Landing/ParallaxLayers/FirstShot/FirstBackground'
import { FirstLandingPageContentPreview } from '../components/Landing/ParallaxLayers/FirstShot/FirstContentScreenshot'
import { ScrollDownArrow } from '../components/Landing/ParallaxLayers/FirstShot/ScrollArrow'
import { SecondLandingPage } from '../components/Landing/ParallaxLayers/SecondShot/Second'
import { SecondLandingPageImagePreview } from '../components/Landing/ParallaxLayers/SecondShot/SecondImageScreenshot'
import { SecondLandingPageBackground } from '../components/Landing/ParallaxLayers/SecondShot/SecondBackground'
import { ThirdLangingPage } from '../components/Landing/ParallaxLayers/ThirdShot/Third'
import { FourthLandingPage } from '../components/Landing/ParallaxLayers/FourthShot/Fourth'
import { FourthLandingPageImagePreview } from '../components/Landing/ParallaxLayers/FourthShot/FourthImageScreenshot'
import { FourthLandingPageBackground } from '../components/Landing/ParallaxLayers/FourthShot/FourthBackground'
import { FifthLandingPage } from '../components/Landing/ParallaxLayers/FifthShot/Fifth'
import { FifthLandingPageBackground } from '../components/Landing/ParallaxLayers/FifthShot/FifthBackground'
import { FifthLandingPageImagePreview } from '../components/Landing/ParallaxLayers/FifthShot/FifthImageScreenshot'
import { ThirdLandingPageContentPreview } from '../components/Landing/ParallaxLayers/ThirdShot/ThirdContentScreenshot'
import { ThirdLandingPageBackground } from '../components/Landing/ParallaxLayers/ThirdShot/ThirdBackground'
import { NavbarLayer } from '../components/Landing/ParallaxLayers/Navbar'
import { SixthLandingPage } from '../components/Landing/ParallaxLayers/SixthShot/Sixth'
import { SixthLandingPageFAQ } from '../components/Landing/ParallaxLayers/SixthShot/SixthFAQ'
import { SixthLandingPageBackground } from '../components/Landing/ParallaxLayers/SixthShot/SixthBackground'
const Description: string =
  'Disadus is a tool that helps students increase their productivity and form school communities with their peers to achieve academic success!'
const renderVideo = () => {
  if (isSafari || isMobileSafari) return true
  return false
}
// import KUTE from "kute.js"
const Home: NextPage = () => {
  const router = useRouter()
  // const scrollable1 = useRef<HTMLDivElement>(null)
  // const video = useRef<HTMLVideoElement>(null)
  const [videoReady, setvideoReady] = useState(0)
  const paraRef = useRef<IParallax>(null)
  useEffect(() => {
    if (videoReady === 1) {
      setTimeout(() => {
        setvideoReady(2)
      }, 500)
    }
    if (videoReady === 2) {
      setTimeout(() => {
        setvideoReady(3)
      }, 300)
    }
    if (videoReady === 3) {
      setTimeout(() => {
        setvideoReady(4)
      }, 1000)
    }
  }, [videoReady])
  useEffect(() => {
    if (paraRef.current) {
      switch (router.query.sec) {
        case 'faq':
          paraRef.current.scrollTo(3.5)
          break
        case 'top':
          paraRef.current.scrollTo(0)
          break
        default:
          break
      }
    }
  }, [router])
  useLayoutEffect(() => {
    localStorage.getItem('token') && router.push('/app')
  }, [])
  // useEffect(() => {
  //   if (video.current && scrollable1.current) {
  //     const sc = scrollable1.current!
  //     const onScroll = () => {
  //       const vid = video.current!

  //       const scrollPercent = (sc.scrollTop / (sc.scrollHeight - sc.clientHeight)) * 100
  //       vid.currentTime = vid.duration * (scrollPercent / 100)
  //     }
  //     sc.addEventListener('scroll', onScroll)
  //     return () => {
  //       sc.removeEventListener('scroll', onScroll)
  //     }
  //   }
  // }, [router])

  return (
    // <div className={`w-full h-full relative overflow-auto dark:bg-gray-900`}>
    <>
      <Head>
        <title>Disadus - The helpful tool students deserve</title>
        <link rel="icon" href="/logoold.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={Description} />
        <meta name="theme-color" content="#66ffe0" />
        {/* link preview */}
        <meta property="og:title" content="Disadus - The helpful tool students deserve" />
        <meta property="og:description" content={Description} />
        <meta property="og:image" content="https://disadus.app/landing/embed.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:url" content="https://disadus.app" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Disadus" />
        <meta property="og:locale" content="en_US" />
      </Head>
      <div
        className={`absolute top-0 left-0 w-full h-0 ${
          videoReady && `translate-y-full`
        } bg-gray-50 dark:bg-gray-900 z-20 duration-500 delay-150 transition-all ${videoReady >= 2 && `hidden`}`}
      />

      <Parallax pages={3.5} className={`scrollbar-none bg-cyan-50 dark:bg-gray-900`} ref={paraRef}>
        <>
          <FirstLandingPageBackground />
          <FirstLandingPageContentPreview ready={videoReady} setReady={setvideoReady} />
          <ScrollDownArrow ready={videoReady} />
          <FirstLandingPage ready={videoReady} />
        </>
        <>
          <SecondLandingPageBackground />
          <SecondLandingPageImagePreview />
          <SecondLandingPage />
        </>
        <>
          <ThirdLandingPageBackground />
          <ThirdLandingPageContentPreview />
          <ThirdLangingPage />
        </>
        <>
          <FourthLandingPageBackground />
          <FourthLandingPageImagePreview />

          <FourthLandingPage />
        </>
        <>
          <FifthLandingPageBackground />
          <FifthLandingPage />
          {/* <FifthLandingPageImagePreview /> */}
        </>
        <>
          <SixthLandingPageBackground />

          <SixthLandingPage />
          <SixthLandingPageFAQ />
        </>

      </Parallax>
      <NavbarLayer />
    </>
    // </div>
  )
}

export default Home
