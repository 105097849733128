import { QuestionMarkCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useState } from 'react'
import { useCommunitySearch } from '../../utils/hooks/community/useCommunitySearch'
import TextBox from '../Inputs/TextBox'
import { CommunitySearchResult } from './CommunitySearchResult'

export const CommunitySearchResults = () => {
  const [query, setQuery] = useState('')
  const results = useCommunitySearch(query)
  return (
    <form onSubmit={e => e.preventDefault()} className={`flex flex-col w-full h-full`}>
      <div className={`flex flex-row gap-4 items-center`}>
        <div className={`w-full h-20 flex flex-row items-center flex-shrink-0 z-10`}>
          <input
            className={`w-full h-full text-lg px-8 dark:bg-gray-750 bg-gray-50 hover:bg-white focus:bg-white drop-shadow-sm hover:shadow-lg focus:shadow-lg dark:text-gray-200 transition-all rounded-xl ${results?.length && query && `rounded-b-none`}`}
            placeholder={`Find your school name here`}
            value={query}
            onChange={e => setQuery(e.target.value)}
            // ref={searchBox}
          />
        </div>
        {/* <div className={`w-6 h-6`}>
          <RefreshIcon className={`w-6 h-6`} />
        </div> */}
      </div>
      <div className={`relative ${results?.length && query? `h-96`: `h-0`} transition-all duration-200 w-full dark:bg-gray-800 rounded-b-xl`}>
        {!!results?.length && !!query && (
          <div className={`flex flex-col overflow-auto absolute w-full h-full scrollbar-none`}>
            {results?.map(result => (
              <CommunitySearchResult community={result} />
            ))}
            {/* <Link href={`/`}>
              <div
                className={`p-3 dark:bg-gray-750 dark:hover:bg-gray-700 w-full flex flex-row gap-4 shadow-md transition-all cursor-pointer group relative overflow-hidden flex-shrink-0 items-center`}
              >
                <div className={`w-12 h-12 z-10 p-2`}>
                  <QuestionMarkCircleIcon className={`w-full h-full`} />
                </div>
                <div className={`flex flex-col gap-2 justify-center z-10`}>
                  <div className={`text-lg font-semibold`}>Can&apos;t find your community?</div>
                  <div className={`text-sm`}>Create your own</div>
                </div>
              </div>
            </Link> */}
          </div>
        )}
      </div>
    </form>
  )
}
