import { PlusIcon } from '@heroicons/react/outline'
import { useState } from 'react'

export const FAQEntry = (props: { question: string; answer: string }) => {
  const { question, answer } = props
  const [open, setOpen] = useState(false)
  return (
    <><div className={`w-full h-min flex flex-col gap-4`}>
      <div className={`flex flex-row gap-4 items-center group cursor-pointer`} onClick={() => setOpen(!open)}>
        <span className={`font-extrabold font-montserrat text-3xl flex flex-row items-center gap-3 flex-grow`}>
          {question}
        </span>
        <PlusIcon className={`w-6 h-6 cursor-pointer ${open && `rotate-45`} transition-all flex-shrink-0`} />
      </div>
      <span className={`font-wsans text-2xl break-words ${!open ? `hidden` : `block`} h-max overflow-hidden`}>
        {answer}
      </span>

    </div><div className={`w-full h-0.5 bg-gray-900/60 last:hidden`} /></>
  )
}
