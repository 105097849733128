import { ParallaxLayer } from '@react-spring/parallax'
import Image from 'next/image'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { isMacOs, useDeviceData } from 'react-device-detect'
export const ThirdLandingPageContentPreview = () => {
  return (
    <ParallaxLayer speed={4} offset={1}>
      <div className={`w-full h-full flex flex-row items-center justify-end`}>
        <div className={`w-1/2 h-min flex flex-row justify-start items-center`}>
          <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start relative`}>
            <div className={`relative`}>
              <div className={`w-full h-full dark:block hidden`}>
                <Image
                  src={'/landing/shot1.png'}
                  width="1920"
                  height="1080"
                  className={`rounded-xl z-20`}
                  priority
                  alt="Screenshot of Disadus"
                />
              </div>
              <div className={`w-full h-full dark:hidden block`}>
                <Image
                  src={'/landing/shot1light.png'}
                  width="1920"
                  height="1080"
                  className={`rounded-xl z-20`}
                  alt="Screenshot of Disadus"
                  // priority
                />
              </div>
              <div
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[130%] h-[130%] dark:opacity-30 opacity-70`}
              >
                <img
                  src={'/landing/shot1bg.png'}
                  className={`dark:contrast-125 dark:brightness-50 hue-rotate-[20deg] w-full h-full`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxLayer>
  )
}
