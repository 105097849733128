import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'

export const FourthLandingPage = () => (
  <ParallaxLayer speed={2.08} offset={1.9} factor={2.2}>
    <div className={`w-full h-full flex flex-row items-center justify-end`}>
      <div className={`w-1/2 h-min flex flex-row justify-start items-center`}>
        <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start ml-12`}>
          <h2 className={`font-extrabold font-montserrat text-4xl flex flex-row items-center gap-3`}>Personalized for <span className={`bg-gradient-to-r from-blue-400 to-emerald-400 text-transparent bg-clip-text font-wsans text-6xl`}>YOU</span></h2>
          <p className={`font-wsans text-2xl`}>
            Disadus is designed for customizability. We&apos;ve made it easy to customize your account to fit your needs from changing how your courses look to how you organize your assignments.
          </p>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
