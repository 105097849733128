import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'
import { CommunitySearchResults } from '../../CommunitySearchResults'

export const FifthLandingPage = () => (
  <ParallaxLayer speed={1} offset={2} factor={1}>
    <div className={`w-full h-full flex flex-row items-center justify-start`}>
      <div className={`w-full h-min flex flex-col justify-center items-center gap-8`}>
        <h2 className={`font-extrabold font-montserrat text-5xl`}>Ready?</h2>
        <p className={`font-wsans text-2xl`}>Start your journey by looking for your school!</p>
        <div className={`w-full max-w-prose`}>
        <CommunitySearchResults />
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
