import { ChevronDownIcon } from '@heroicons/react/outline'
import { ParallaxLayer } from '@react-spring/parallax'

export const ScrollDownArrow = (props: { ready: number }) => (
  <ParallaxLayer speed={15} factor={0.1} offset={0.9}>
    <div className={`w-full h-full flex flex-row justify-center items-end pb-8 ${props.ready < 3 && `opacity-0`} transition-all duration-500 delay-[2000ms]`}>
      <ChevronDownIcon className={`w-4 h-4 dark:text-white text-gray-800/40 animate-bounce`} />
    </div>
  </ParallaxLayer>
)
