import { UserGroupIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { Community } from '../../utils/types/communityTypes'

export const CommunitySearchResult = (props: { community: Community }) => {
  const { community } = props
  return (
    <Link href={`/signup/?community=${community.id}`}>
      <div
        className={`p-3 w-full flex flex-row gap-4 first:border-none border-t dark:border-gray-700  transition-all cursor-pointer group relative overflow-hidden flex-shrink-0 items-center`}
      >
        <div className={`w-12 h-12 z-10`}>
          <img src={community.image} alt="" className={`w-12 h-12 rounded-xl shadow-md`} />
        </div>
        <div className={`flex flex-col gap-2 justify-center z-10`}>
          <div className={`text-lg font-semibold`}>{community.name}</div>
          <div className={`text-sm font-medium flex flex-row gap-1 items-center`}><UserGroupIcon className={`w-4 h-4`} /> {community.members.length}</div>
        </div>
        {community?.vanitybg && (
          <div
            className={`w-full h-full absolute top-0 left-0 z-0 opacity-50 -translate-x-full group-hover:translate-x-0 transition-all duration-300`}
            style={{
              WebkitMaskImage: `linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 10%, rgba(0,0,0,0.5) 20%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 90%)`,
            }}
          >
            <img src={community.vanitybg} className={`w-full h-full object-cover object-center`} />
          </div>
        )}
      </div>
    </Link>
  )
}
