import { ParallaxLayer, ParallaxLayerProps } from '@react-spring/parallax'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { FAQEntry } from '../../FAQs/FAQEntry'

export const SixthLandingPageFAQ = () => {
  return (
    <ParallaxLayer speed={1} offset={2.99999} factor={1} id={`faq`}>
      <div className={`w-full h-full flex flex-row items-center justify-end`}>
        <div className={`w-1/2 h-min flex flex-row justify-start items-center`}>
          <div className={`flex flex-col w-2/3 h-min items-start justify-start gap-3 ml-12`}>
            <FAQEntry
              question={'How much does it cost?'}
              answer={`Zero. We don't think students should be required to fork up money to use Disadus. However, there may be an optional membership program in the future!`}
            />
            <FAQEntry
              question={"Why can't I find my school?"}
              answer={`Most school communities are set up by a student at that specific school. If there isn't a community for your school, you could create your own or ask us to create one for you!`}
            />
            <FAQEntry
              question={'When will you support PowerSchool/Canvas/Other Learning Service?'}
              answer={`We have plans to support other learning services in the future, however, we are currently only supporting Schoology. You can get notified when we support other learning services by joining our Discord server.`}
            />
            <FAQEntry
              question={'How do you make money?'}
              answer={`At the moment, we don't make money. We don't prioritize profit at the moment. However, we may explore some non-intrusive methods in the future.`}
            />
            <FAQEntry
              question={'How do I propose a suggestion?'}
              answer={`You can propose a suggestion by shooting us a message on Discord in the #faq channel.`}
            />
            
          </div>
        </div>
      </div>
    </ParallaxLayer>
  )
}
