import { ParallaxLayer } from '@react-spring/parallax'
import Link from 'next/link'

export const SixthLandingPage = () => (
  <ParallaxLayer speed={1} offset={2.99999} factor={1}>
    <div className={`w-full h-full flex flex-row items-center justify-start`}>
      <div className={`w-1/2 h-min flex flex-row justify-end items-center`}>
        <div className={`flex flex-col gap-8 w-2/3 h-min items-start justify-start ml-12`}>
          <h2 className={`font-extrabold font-montserrat text-5xl flex flex-row items-center gap-3`}>
            Frequently Asked Questions
            {/* <span
              className={`bg-gradient-to-r from-blue-400 to-emerald-400 text-transparent bg-clip-text font-wsans text-6xl`}
            >
              YOU
            </span> */}
          </h2>
        </div>
      </div>
    </div>
  </ParallaxLayer>
)
