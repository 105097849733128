import { ParallaxLayer } from '@react-spring/parallax'

export const FourthLandingPageBackground = () => (
  <ParallaxLayer speed={1} offset={1.9999}>
    <div className={`w-full h-full`}>
      <div className={`w-full h-[100%] relative dark:opacity-100 opacity-100 bg-blue-50 dark:bg-[rgb(15,15,17)]`}>
        {/* <div
          className={`absolute top-0 left-0 w-full h-full  z-10`}
          style={{
            backgroundColor: `#c0ff99`,
            backgroundImage: `radial-gradient(at 37% 46%, hsla(170,78%,61%,1) 0px, transparent 50%),
          radial-gradient(at 0% 56%, hsla(218,99%,77%,1) 0px, transparent 50%),
          radial-gradient(at 24% 16%, hsla(140,76%,68%,1) 0px, transparent 50%),
          radial-gradient(at 6% 46%, hsla(126,66%,69%,1) 0px, transparent 50%),
          radial-gradient(at 78% 59%, hsla(194,83%,77%,1) 0px, transparent 50%),
          radial-gradient(at 55% 51%, hsla(56,91%,69%,1) 0px, transparent 50%),
          radial-gradient(at 71% 14%, hsla(221,76%,75%,1) 0px, transparent 50%)`,
          }}
        /> */}
        {/* <div
          className={`absolute top-0 left-0 w-full h-full bg-cover bg-center blur-[0px] opacity-100`}
          style={{
            backgroundImage: `url('/landing/wavebg.png')`,
            WebkitMaskImage: `linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0.1) 100%)`,
          }}
        /> */}
      </div>
    </div>
  </ParallaxLayer>
)
